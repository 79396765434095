<template>
  <b-row>
    <b-col cols="12">
      <!-- Tabela de Cargos Diretivos -->
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listExecutivePositions"
        :title-table="$t('Executive Positions')"
        module="executivePositionsSettingsModule"
        sort-name="nome"
        :show-btn-add="verificarPermissao('ROLE_ADMIM_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ADMIM_DELETAR')"
        @add="addNew"
        @edit="edit"
      />

      <!-- Formulário de Cadastro/Edição -->
      <b-card v-else :title="titleForm">
        <validation-observer ref="form">
          <b-form @submit.prevent>
            <b-row>
              <!-- Descrição -->
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="descrição"
                  rules="required"
                >
                  <b-form-group label="Descrição" label-for="v-description">
                    <b-form-input
                      id="v-description"
                      v-model="executivePositionPlace.nome"
                      :state="errors.length > 0 ? false : null"
                      placeholder="descrição"
                      :disabled="!canEditOrInsert"
                      data-cy="description-input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Ativo -->
              <b-col v-if="executivePositionPlace.id" cols="12">
                <b-form-group>
                  <b-form-checkbox
                    v-model="executivePositionPlace.ativo"
                    name="check-button"
                    switch
                    inline
                    :disabled="!canEditOrInsert"
                    data-cy="active-checkbox"
                  >
                    {{ executivePositionPlace.ativo ? 'Sim' : 'Não' }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <!-- Botões de Ação -->
              <ButtonsFormVue
                :permission-insert="verificarPermissao('ROLE_ADMIM_INSERIR')"
                :permission-edit="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
                :disable-form="false"
                @save="save"
                @cancel="cancel"
              />
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { verificarPermissao } from '@/auth/utils'
import mixinsGlobal from '@/mixins'

export default {
  name: 'ExecutivePosition',
  mixins: [mixinsGlobal],
  components: {
    BRow,
    BCol,
    TableComponents,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BFormCheckbox,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  computed: {
    ...mapState('executivePositionsSettingsModule', {
      listExecutivePositions: (state) => state.listExecutivePositions,
      executivePosition: (state) => state.executivePosition
    }),
    canEditOrInsert() {
      return (
        (!this.executivePositionPlace.id &&
          this.verificarPermissao('ROLE_ADMIM_INSERIR')) ||
        (this.executivePositionPlace.id &&
          this.verificarPermissao('ROLE_ADMIM_ATUALIZAR'))
      )
    }
  },
  data() {
    return {
      verificarPermissao,
      required,
      showForm: false,
      titleForm: 'Cadastro de Cargos Diretivos',
      columns: [
        { label: this.$t('id'), field: 'id' },
        { label: this.$t('Description'), field: 'nome' },
        { label: this.$t('Active'), field: 'ativo' },
        { label: this.$t('Action'), field: 'action' }
      ],
      pageConfig: { page: 1, size: 10, sort: 'nome', order: 'asc' },
      executivePositionPlace: { nome: '', ativo: true }
    }
  },
  mounted() {
    this.loadTable()
  },
  methods: {
    loadTable() {
      this.$store.dispatch(
        'executivePositionsSettingsModule/loadTable',
        this.pageConfig
      )
    },
    addNew() {
      this.showForm = true
      this.cleanObjeto()
    },
    edit(data) {
      this.$store.dispatch(
        'executivePositionsSettingsModule/prepareToEditExecutivePositions',
        data
      )
      this.executivePositionPlace = { ...data }
      this.showForm = true
    },
    cancel() {
      this.showForm = false
      this.cleanObjeto()
      this.loadTable()
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) return

        const action = this.executivePositionPlace.id
          ? 'editExecutivePositions'
          : 'insertNewExecutivePositions'

        this.$store
          .dispatch(
            `executivePositionsSettingsModule/${action}`,
            this.executivePositionPlace
          )
          .then((response) => {
            if (response?.response) {
              this.MensagemError(
                'Falha ao Salvar',
                response.response.data.error
              )
              return
            }
            this.showMessageSuccess(
              this.executivePositionPlace.id ? 'Editar' : 'Salvar',
              'Operação realizada com sucesso!'
            )
            this.cancel()
          })
      })
    },
    cleanObjeto() {
      this.executivePositionPlace = { nome: '', ativo: true }
    }
  }
}
</script>
